
import { defineComponent, ref } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";

export default defineComponent({
  name: "kt-widget-6",
  components: {
    Dropdown1,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = ref([
      {
        image: "/media/stock/600x400/img-20.jpg",
        title: "Cup & Green",
        size: "87KB",
        color: "success",
        status: "Approved",
      },
      {
        image: "/media/stock/600x400/img-19.jpg",
        title: "Yellow Background",
        size: "1.2MB",
        color: "warning",
        status: "In Progress",
      },
      {
        image: "/media/stock/600x400/img-25.jpg",
        title: "Nike & Blue",
        size: "87KB",
        color: "success",
        status: "Success",
      },
      {
        image: "/media/stock/600x400/img-24.jpg",
        title: "Red Boots",
        size: "345KB",
        color: "danger",
        status: "Rejected",
      },
    ]);

    return {
      list,
    };
  },
});
